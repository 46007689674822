import Vue from 'vue'
import App from './App.vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.css'
import VueRouter from 'vue-router'
import Table from '@/components/Table'
import Entrance from '@/components/Entrance'
import NotFound from '@/components/404'
import Firebase from "firebase";
import VueClipboard from 'vue-clipboard2'
 

require("firebase/firestore");

const config = {
  apiKey: "AIzaSyCZYLZwbZz2jeQcZAnSobRSRSPYUcV5czQ",
  authDomain: "vuedle-ecb2b.firebaseapp.com",
  projectId: "vuedle-ecb2b",
  storageBucket: "vuedle-ecb2b.appspot.com",
  messagingSenderId: "187037308694",
  appId: "1:187037308694:web:c1e13b8c0c70769ebf6237",
  measurementId: "G-GR69J4YWLR",
};
var firebaseApp = Firebase.initializeApp(config);


export const firestore = firebaseApp.firestore()

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueMaterial)
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {path: '/', component: Entrance, name: 'index'},
    {path: '/room/:id', component: Table, name: 'room'},
    {path: '*', component: NotFound, name: '404'},
  ], 
  mode:'hash'
})

// change single option
Vue.material.locale.dateFormat = 'dd/MM/yyyy'

// change multiple options
Vue.material = {
  ...Vue.material,
  locale: {
    ...Vue.material.locale,
    dateFormat: 'dd/MM/yyyy',
    firstDayOfAWeek: 1
  }
}


const app = new Vue({
  router,
  render: h => h(App),

}).$mount('#app')
