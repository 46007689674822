<template>
  <div class="table">
    <form novalidate @submit.stop.prevent="addUser(name)">
      <md-card>
        <md-card-header>
          <div class="md-title">Vuedle</div>
          <div class="smd-subhead">by Robin</div>

          <md-field v-if="admin">
            <label>Description</label>
            <md-textarea v-model="description" md-autogrow v-on:change="setDescription()"></md-textarea>
          </md-field>
          <md-content v-else
            ><span class="medium">{{ description }}</span></md-content
          >
        </md-card-header>
        <md-card-content>
          <md-table>
            <md-table-row>
              <md-table-head>Name</md-table-head>
              <md-table-head v-for="(name, i) in dayNames" v-bind:key="name">
                <span v-if="isSelected(i)" class="highlight"> {{ name }}</span>
                <span v-else>{{ name }}</span>
              </md-table-head>
            </md-table-row>
            <md-table-row v-for="user in users" v-bind:key="user in users">
              <template v-if="user.id != uuid">
                <md-table-cell
                  ><p class="medium">{{ user.name }}</p></md-table-cell
                >
                <md-table-cell v-for="i in 7" v-bind:key="i">
                  <md-checkbox
                    v-model="user.days"
                    :value="i"
                    disabled
                  ></md-checkbox>
                </md-table-cell>

                <md-table-cell>
                  <md-button
                    v-if="admin"
                    class="md-raised md-accent md-dense"
                    v-on:click="deleteUser(user.id)"
                    >Delete</md-button
                  ></md-table-cell
                >
              </template>
            </md-table-row>
            <md-table-row>
              <md-table-cell v-if="input">
                <md-field
                  ><md-input
                    v-model="name"
                    maxlength="20"
                    placeholder="Your name"
                    pattern="[A-Za-z0-9]+"
                /></md-field>
              </md-table-cell>
              <md-table-cell v-else>
                <p v-on:click="toggleInput()" class="medium">{{ name }}</p>
              </md-table-cell>
              <md-table-cell v-for="i in 7" v-bind:key="i">
                <md-checkbox
                  v-model="days"
                  :value="i"
                  class="md-primary"
                ></md-checkbox>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <br />

          <md-button
            type="submit"
            class="md-raised md-primary"
            v-on:click="addUser(name)"
            >Submit</md-button
          >
          <br />

          <md-divider></md-divider>

          <br />


          <share-url />
        </md-card-content>
      </md-card>
      <md-snackbar md-position="left" :md-active.sync="showSnackbar">
        <span v-if="name !== ''">Your answer has been submitted!</span>
        <span v-else>You did not enter a name!</span>
      </md-snackbar>
    </form>
  </div>
</template>

<script>
import Share from "./Share.vue";
import { v4 as uuidv4 } from "uuid";
import { firestore } from "../main";

export default {
  name: "Table",
  components: {
    "share-url": Share,
  },
  data: function () {
    return {
      users: [],
      uuid: null,
      input: true,
      days: [],
      name: "",
      admin: false,
      roomId: "",
      description: "",
      showSnackbar: false,
      selectedDays: [],
      dayNames: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    };
  },
  methods: {
    addUser: async function (name) {
      this.users.push({
        name: name,
        id: localStorage.getItem("id"),
        days: this.days,
        admin: this.admin,
      });
      this.input = false;
      this.showSnackbar = true;

      const ref = firestore
        .collection("rooms")
        .doc(this.roomId)
        .collection("users")
        .doc(this.uuid);

      await ref.set({
        name: this.name,
        id: this.uuid,
        days: this.days,
        admin: this.admin,
      });
    },
    toggleInput: function () {
      if (this.name !== "" && this.input === true) {
        this.input = false;
      } else {
        this.input = true;
      }
    },
    setIdentifier: function () {
      if (!localStorage.getItem("id")) {
        localStorage.setItem("id", uuidv4());
      }
      this.uuid = localStorage.getItem("id");
    },
    getUsers: async function () {
      this.users = [];
      const userCollection = await firestore
        .collection("rooms")
        .doc(this.roomId)
        .collection("users")
        .get()
        .then((collection) => {
          if (collection.docs.length > 0) {
            collection.forEach((doc) => {
              if (doc.id == this.uuid) {
                this.newEntry = false;
                this.name = doc.data().name;
                this.days = doc.data().days ? doc.data().days : [];
                this.admin = doc.data().admin;
                if (doc.data().name) this.input = false;
              }

              let days = [];
              if (Array.isArray(doc.data().days)) {
                days = doc.data().days;
              } else {
                days = [doc.data().days];
              }

              this.users.push({
                name: doc.data().name,
                id: doc.id,
                days: days,
                admin: doc.data().admin,
              });
            });
            this.getSelection();
          } else {
            this.$router.push({ name: "index" });
          }
        });
    },
    updateUser: async function () {
      if (this.name != "") {
        firestore
          .collection("rooms")
          .doc(this.roomId)
          .collection("users")
          .doc(this.uuid)
          .set(
            {
              name: this.name,
              days: this.days,
            },
            { merge: true }
          )
          .catch((err) => {
            console.log(err.message);
          });
      }
    },
    deleteUser: async function (id) {
      await firestore
        .collection("rooms")
        .doc(this.roomId)
        .collection("users")
        .doc(id)
        .delete()
        .catch((err) => {
          console.log(err.message);
        })
        .then(this.getUsers());
    },
    setDescription: async function () {
      await firestore
        .collection("rooms")
        .doc(this.roomId)
        .set({ description: this.description }, { merge: true });
    },
    getDescription: async function () {
      let roomRef = await firestore.collection("rooms").doc(this.roomId).get();
      this.description = roomRef.data().description;
    },
    getSelection: function () {
      let tmp = [0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < this.users.length; i++) {
        let user = this.users[i];
        for (let y = 0; y < user.days.length; y++) {
          tmp[user.days[y]]++;
        }
      }
      let max = Math.max(...tmp);
      let selectedDays = [];
      if (max > 0) {
        for (let i = 0; i < tmp.length; i++) {
          if (max === tmp[i]) selectedDays.push(i - 1);
        }
      }
      this.selectedDays = selectedDays;
      console.log(selectedDays);
    },
    isSelected(n) {
      return this.selectedDays.indexOf(n) > -1;
    },
  },
  beforeMount() {
    this.setIdentifier();
    this.getUsers();
    this.getDescription();
  },
  created() {
    this.roomId = this.$route.params.id;
  },
};
</script>

<style>
.medium {
  font-size: 16px;
}
.highlight {
  color: #448aff;
}
</style>

//  color: #ff5252;