<template>
  <div class="entrance">
    <md-empty-state
      md-icon="devices_other"
      md-label="Create your first Vuedle"
      md-description="Creating a Vuedle, you'll be able schedule meetings with other people."
    >
      <md-button class="md-primary md-raised" v-on:click="redirectToRoom()"
        >Create a room</md-button
      >
    </md-empty-state>
  </div>
</template>

<script>
import { firestore } from "../main";
import { v4 as uuidv4 } from "uuid";
import {
  uniqueNamesGenerator,
  adjectives,
  animals,
} from "unique-names-generator";
export default {
  name: "Entrance",
  data: function () {
    return {
      uuid: null,
    };
  },
  methods: {
    redirectToRoom: async function () {
      let roomId = uniqueNamesGenerator({
        dictionaries: [adjectives, adjectives, animals],
        separator: "-",
        style: "lowercase",
      });
      const roomDoc = await firestore
        .collection("rooms")
        .doc(roomId)
        .collection("users")
        .doc(this.uuid)
        .set({ admin: true, id: this.uuid });
      this.$router.push({
        name: "room",
        params: { id: roomId },
      });
    },
  },
  created() {
    if (!localStorage.getItem("id")) {
      localStorage.setItem("id", uuidv4());
    }
    this.uuid = localStorage.getItem("id");
  },
};
</script>