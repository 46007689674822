<template>
  <div class="share" v-clipboard:copy="url" md-duration="2000" 
 v-clipboard:success="successCopy" v-clipboard:error="errorCopy">
    <md-field v-if="url">
      <label>Click to copy and share the room</label>
      <md-input
        placeholder="url"
        class="md-caption"
        v-model="url"
        disabled
      ></md-input>
    </md-field>
    <md-snackbar md-position="left" :md-active.sync="show">
      <span v-if="copySuccess"
        >The url has been copied to your clipboard!
      </span>
      <span v-else>Could not copy the url.</span>
    </md-snackbar>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/1.5.10/clipboard.min.js"></script>

<script>
export default {
  name: "Share",
  data: function () {
    return {
      copySuccess: true,
      show: false,
    };
  },
  methods: {
    successCopy: function () {
    console.log("h")
      this.copySuccess = true;
      this.show = true;
    },
    errorCopy: function () {
        console.log("h")
      this.copySuccess = false;
      this.show = true;
    },
  },
  created() {
    this.url = window.location.origin + "/#" + this.$route.fullPath;
  },
};
</script>
<style scoped>
.share {
  background-color: rgba(0, 0, 0, 0.08);
  padding-inline: 10px;
}
</style>
