<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  methods: {
      created() {
        console.log(this.$route.params)
      }
  }
}
</script>
<style>
#app {
  
}
</style>